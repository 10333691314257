import { useCallback, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import * as AuthMain from "./auth/AuthMain";
import { userState } from "./interface/MainInterface";
import { useWindowSize } from "./utils/useWindowSize";
import { calculateWindowSize } from "./utils/helpers";
import "./App.css";

import MainConteiner from "./mainContainer/MainConteiner";
import Footer from "./mainContainer/Footer";
import Header from "./mainContainer/Header";

let userParam: userState = {
  id: "",
  userName: "",
  userInfo1: "",
  userInfo2: "",
  userInfo3: "",
  userInfo4: "",
  userType: "",
  userDept: "",
  readDept: "",
  isAuth: false,
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: "55px !important",
  padding: theme.spacing(0, 0),
  justifyContent: "flex-end",
}));

const largeDrawerWidth: number = 250;
const smallDrawerWidth: number = 74;
const zeroDrawerWidth: number = 0;

const theme = createTheme({
  typography: {
    fontFamily: "Noto Sans KR, Apple SD Gothic Neo, Roboto",
  },
  palette: {
    primary: {
      main: "#ff6c58",
      contrastText: "#fff",
    },
  },
  zIndex: { drawer: 990 },
});

function App() {
  const windowSize = useWindowSize();
  const [userState, setUserState] = useState<userState>();

  const [winSize, setWinSize] = useState("lg");
  const [headerLarge, setHeaderLarge] = useState(true);
  const [open, setOpen] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState(largeDrawerWidth);
  const [contentMargin, setContentMargin] = useState(largeDrawerWidth);

  const [selectedMenu, setSelectedMenu] = useState("");

  async function setUserInfo() {
    const result = AuthMain.getSession();
    if (result !== null) {
      userParam.id = result.id;
      userParam.userName = result.userName;
      userParam.userInfo1 = result.userInfo1;
      userParam.userInfo2 = result.userInfo2;
      userParam.userInfo3 = result.userInfo3;
      userParam.userInfo4 = result.userInfo4;
      userParam.userType = result.userType;
      userParam.userDept = result.userDept;
      userParam.readDept = result.readDept;
      userParam.isAuth = true;
    }
    setUserState(userParam);
  }

  const handleDrawer = useCallback(() => {
    setHeaderLarge(!headerLarge);

    if (winSize === "xs") {
      setContentMargin((contentMargin) => zeroDrawerWidth);
      if (!headerLarge) {
        setDrawerWidth((drawerWidth) => largeDrawerWidth);
      } else {
        setDrawerWidth((drawerWidth) => zeroDrawerWidth);
      }
    } else {
      if (!headerLarge) {
        setDrawerWidth((drawerWidth) => largeDrawerWidth);
        setContentMargin((contentMargin) => largeDrawerWidth);
      } else {
        setDrawerWidth((drawerWidth) => smallDrawerWidth);
        setContentMargin((contentMargin) => smallDrawerWidth);
      }
    }
  }, [headerLarge, winSize]);

  const menuOver = () => {
    if (winSize === "xs") {
      setHeaderLarge(true);
      setDrawerWidth((drawerWidth) => largeDrawerWidth);
    } else setDrawerWidth((drawerWidth) => largeDrawerWidth);
  };

  const menuOut = () => {
    if (winSize === "xs") {
      setHeaderLarge(false);
      setDrawerWidth((drawerWidth) => zeroDrawerWidth);
    } else setDrawerWidth((drawerWidth) => smallDrawerWidth);
  };

  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (size === "xs") {
      setHeaderLarge(false);
      setContentMargin((contentMargin) => zeroDrawerWidth);
      setDrawerWidth((drawerWidth) => zeroDrawerWidth);
    } else if (size === "md" || size === "sm") {
      setHeaderLarge(false);
      setContentMargin((contentMargin) => smallDrawerWidth);
      setDrawerWidth((drawerWidth) => smallDrawerWidth);
    } else {
      setHeaderLarge(true);
      setContentMargin((contentMargin) => largeDrawerWidth);
      setDrawerWidth((drawerWidth) => largeDrawerWidth);
    }
    setWinSize(size);
    // console.log("size : ", size);
  }, [windowSize]);

  useEffect(() => {
    setUserInfo();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {userState !== undefined && (
          <BrowserRouter>
            <Header
              userState={userState}
              open={open}
              winSize={winSize}
              selectedMenu={selectedMenu}
              headerLarge={headerLarge}
              drawerWidth={drawerWidth}
              largeDrawerWidth={largeDrawerWidth}
              handleDrawer={handleDrawer}
              menuOver={menuOver}
              menuOut={menuOut}
            />
            <DrawerHeader />
            <MainConteiner userState={userParam} drawerWidth={contentMargin} setSelectedMenu={setSelectedMenu} />
            <Footer />
          </BrowserRouter>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
