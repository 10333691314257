import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const Footer = () => {
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 699 }}
      elevation={3}
    >
      <Stack
        spacing={0}
        direction="row"
        sx={{ width: "100%", justifyContent: "flex-end", height: 48 }}
      >
        <Typography
          variant="h6"
          sx={{
            margin: "auto",
            fontSize: "0.9rem",
            color: "#869099",
            fontWeight: "bolder",
          }}
          className="roboto"
        >
          &copy;HYUNDAI Department Store. ALL RIGHTS RESERVED.
        </Typography>
        <Box
          component="div"
          sx={{ position: "absolute", right: "10px", bottom: "12px" }}
        >
          <Stack direction="row" spacing={2} sx={{ height: "20px" }}>
            <Button>
              <Typography
                sx={{ color: "#888", fontSize: "0.9rem" }}
                className="roboto"
              >
                서비스이용약관
              </Typography>
            </Button>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Button>
              <Typography
                sx={{ color: "#888", fontSize: "0.9rem" }}
                className="roboto"
              >
                개인정보처리방침
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};

export default Footer;
