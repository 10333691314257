import React, { useEffect, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";

const DeptChip = (props: any) => {
  const theme = useTheme();

  //   하드코딩말고 가져오는게 좋음
  const deptInfo = [
    "마케팅/지원",
    "패션",
    "영/스포츠/유플렉스",
    "아동",
    "리빙",
    "명품",
    "뷰티/화장품",
    "식품",
    "아울렛",
    "VIP",
  ];

  const [checkedDept, setCheckedDept] = useState<any>(
    props.defaultDept === "전체" ? deptInfo : [props.defaultDept]
  );

  const handleChange = (event: any) => {
    console.log(event.target.value);
    setCheckedDept(event.target.value);
    props.changeDept(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const getStyles = (
    name: string,
    checkedDept: readonly string[],
    theme: Theme
  ) => {
    return {
      fontWeight:
        checkedDept.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  return (
    <div>
      {props.userState.userType <= 2 ?
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-multiple-chip-label">조직</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={checkedDept}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value: any) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {deptInfo.map((dept) => (
            <MenuItem
              key={dept}
              value={dept}
              style={getStyles(dept, checkedDept, theme)}
            >
              {dept}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      :
      <TextField
        id="txtCopyCnt"
        label="부서"
        variant="outlined"
        size="small"
        value={props.userState.userInfo2 + "/" + props.userState.userInfo3}
        type="search"
        autoComplete="off"
        inputProps={{ enterKeyHint: "Enter" }}
        fullWidth
      />
      }
    </div>
  );
};

export default DeptChip;
