import { AES, enc } from "crypto-js";
import { appConfig } from "../config/Config";
export function getSession() {
  const encObjString = window.localStorage.getItem(appConfig.sessionName);

  if (!encObjString) return null;
  try {
    const bytes = AES.decrypt(encObjString, appConfig.seed);
    const decrypted = bytes.toString(enc.Utf8);
    let obj = JSON.parse(decrypted);
    if (Date.now() > obj.expire) {
      window.localStorage.removeItem(appConfig.sessionName);
      return false;
    } else {
      obj.expire = Date.now() + appConfig.sessionTimeout;
      const objString = JSON.stringify(obj);
      const encObjString = AES.encrypt(objString, appConfig.seed).toString();
      window.localStorage.setItem(appConfig.sessionName, encObjString);
    }

    return obj;
  } catch (error) {
    window.localStorage.removeItem(appConfig.sessionName);
    return null;
  }
}

export function setSession(sessionData: any) {
  const sessionObj: any = {
    id: sessionData.id,
    userName: sessionData.user_name,
    userInfo1: sessionData.user_info1,
    userInfo2: sessionData.user_info2,
    userInfo3: sessionData.user_info3,
    userInfo4: sessionData.user_info4,
    userType: sessionData.user_type,
    userDept: sessionData.user_dept,
    readDept: sessionData.read_dept,
    expire: Date.now() + 1000 * 60 * 60 * 24, // 24시간
  };
  const objString = JSON.stringify(sessionObj);
  const encObjString = AES.encrypt(objString, appConfig.seed).toString();
  window.localStorage.setItem(appConfig.sessionName, encObjString);
}

export function signOut() {
  window.localStorage.removeItem(appConfig.sessionName);
  return true;
}
