import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";

import { userState } from "../interface/MainInterface";
import * as AuthMain from "../auth/AuthMain";

import Login from "../page/login/Login";
import Agree from "../page/policy/Agree";
import MyCopyList from "../page/myPage/MyCopyList";
import MyLikeList from "../page/myPage/MyLikeList";
import TotalCopyList from "../page/myPage/TotalCopyList";
import DashBoard from "../page/dashboard/DashBoard";
import MngModel from "../page/setting/MngModel";
import MngReplace from "../page/setting/MngReplace";
import ModelSelect from "../page/setting/ModelSelect";
import QnaList from "../page/qna/QnaList";
import QnaDetail from "../page/qna/QnaDetail";
import MultiCopyWriter from "../page/copyWriter/MultiCopyWriter";
import DailyMakeContent from "../page/statistics/dailyMakeContent/DailyMakeContent";
import TokenStatusByDept from "../page/statistics/tokenStatusByDept/TokenStatusByDept";
import DailyCallApi from "../page/statistics/dailyCallApi/DailyCallApi";
import GiftMsgList from "../page/adminMng/GiftMsgList";

interface propsType {
  userState: userState;
  drawerWidth: number;
  setSelectedMenu: any;
}

const MainConteiner = (props: propsType) => {
  let navigate = useNavigate();

  React.useEffect(() => {
    if (!props.userState.isAuth) navigate("/login");
  }, [props.userState.isAuth, navigate]);

  // 세션체크 및 선택된 메뉴 설정
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname !== "/login") {
      const userState = AuthMain.getSession();
      if (userState === false) {
        window.alert("세션이 종료되었습니다.\n다시 로그인해주세요.");
        window.location.href = "/login";
      }
    }

    props.setSelectedMenu(location.pathname);
  }, [location, props]);

  return (
    <Box
      sx={{
        ml: `${props.drawerWidth}px`,
        p: 2,
        transition: "margin 0.5s",
        mb: "2.5rem !important",
      }}
    >
      <Routes>
        <Route path="/" element={<DashBoard userState={props.userState} />} />
        <Route
          path="/dashboard"
          element={<DashBoard userState={props.userState} />}
        />
        <Route
          path="/multicopyWriter"
          element={<MultiCopyWriter userState={props.userState} />}
        />
        <Route
          path="/myCopyList"
          element={<MyCopyList userState={props.userState} />}
        />
        <Route
          path="/myLikeList"
          element={<MyLikeList userState={props.userState} />}
        />
        <Route
          path="/totalCopyList"
          element={<TotalCopyList userState={props.userState} />}
        />
        <Route path="/qna" element={<QnaList userState={props.userState} />} />
        <Route
          path="/qna/detail"
          element={<QnaDetail userState={props.userState}/>}
        />
        <Route
          path="/mngReplace"
          element={<MngReplace userState={props.userState} />}
        />
        <Route
          path="/modelSelect"
          element={<ModelSelect userState={props.userState} />}
        />
        <Route
          path="/mngModel"
          element={<MngModel userState={props.userState} />}
        />
        <Route
          path="/dailycallapi"
          element={<DailyCallApi userState={props.userState} />}
        />
        <Route
          path="/dailymakecontent"
          element={<DailyMakeContent userState={props.userState} />}
        />
        <Route
          path="/tokenstatusbydept"
          element={<TokenStatusByDept userState={props.userState} />}
        />
        {props.userState.userType <= "2" && (
          <Route
            path="/giftMsgList"
            element={<GiftMsgList />}
          />
        )}
        <Route path="*" element={<Navigate replace to="/" />} />
        <Route path="/login" element={<Login />}></Route>
        <Route
          path="/agree"
          element={<Agree />}
        ></Route>
      </Routes>
    </Box>
  );
};

export default MainConteiner;
