import dayjs, { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import * as XLSX from "xlsx";
import { Box, Button, CardMedia, Divider, Pagination, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { HttpMainApi } from "../../interface/main-api";
import "./GiftMsgList.css";

const mainApi = new HttpMainApi();

interface DataRow {
    sk: string;
    target: string;
    keyword: string;
    content: string;
    user_name: string;
    create_time: string; // 또는 Date, 필요에 따라 적절한 타입 사용
  }

const GiftMsgList = () => {
    const toastRef: any = useRef();

    const [fromDate, setFromDate] = useState<Dayjs | null>(
        dayjs().add(-60, "day")
      );
    const [toDate, setToDate] = useState<Dayjs | null>(dayjs());

    const [isLoading, setIsLoading] = useState(false);
    const [dataList, setDataList] = useState<any>([]);
    const [question, setQuestion] = useState("");
    const [selectedQuestionOption, setSelectedQuestionOption] = useState("target");

    const [rows, setRows] = useState([]);
    const columns: GridColDef[] = [
        {field: 'sk', headerName: '순번', headerAlign: "center", align: "center", flex: 1},
        {field: 'target', headerName: "대상/연령", headerAlign: "center", align: "center", flex: 1},
        {field: 'keyword', headerName: "상황/테마", headerAlign: "center", align: "center", flex: 1},
        {field: 'content', headerName: "내용", headerAlign: "center", align: "center", flex: 4},
        {field: 'user_name', headerName: "생성자", headerAlign: "center", align: "center", flex: 1},
        {field: 'create_time', headerName: "생성일", headerAlign: "center", align: "center", flex: 1, 
            valueGetter: ({ row }: { row: DataRow }) => 
                formatCreateTime(row.create_time),
        },
    ];

    useEffect(() => {
        get_gift_msg_list();
        return () => {
          setIsLoading(false);
          setDataList([]);
          setRows([]);
        //   setFromDate(null);
        //   setToDate(null);
        };
    }, []);

    useEffect(() => {
        let temp_list = [];
        if (question === "") {
            setRows(dataList);
        } else {
            const filteredList = dataList.filter((item: any) => {
                const value = item[selectedQuestionOption];
                return value && typeof value === "string" && value.includes(question);
            });
            setRows(filteredList);
        }
    }, [selectedQuestionOption, dataList, question]);

    useEffect(() => {
        // fromDate와 toDate가 모두 유효한 Dayjs 객체이고, YYYY-MM-DD 형식을 충족하는지 확인
        const isFromDateValid =
          fromDate &&
          fromDate.isValid() &&
          fromDate.format("YYYY-MM-DD") === fromDate.format("YYYY-MM-DD");
        const isToDateValid =
          toDate &&
          toDate.isValid() &&
          toDate.format("YYYY-MM-DD") === toDate.format("YYYY-MM-DD");
        // 둘 다 유효한 경우에만 get_hongbo_data_list 함수 실행
        if (isFromDateValid && isToDateValid) {
            get_gift_msg_list();
        }
    }, [fromDate, toDate]);

    const handleSelectOptionChange = (event: any) => {
        // setCurrentPage(1);
        setSelectedQuestionOption(event.target.value);
    };

    function formatCreateTime(createTime: string): string {
        // 'create_time'에서 연, 월, 일을 추출
        const year = createTime.substring(0, 4);
        const month = createTime.substring(4, 6);
        const day = createTime.substring(6, 8);
    
        // 포맷된 날짜 문자열 반환
        return `${year}-${month}-${day}`;
    };

    const downloadExcel = (dataList: any[], columns: any[]) => {
        // 각 행에 대해 엑셀 파일에 포함될 값을 계산
        const dataForExcel = dataList.map((row) => {
          let newRow: { [key: string]: any } = {};
          columns.forEach((col) => {
            // valueGetter가 정의된 경우 해당 로직을 사용하여 값을 계산
            if (col.valueGetter) {
              newRow[col.headerName] = col.valueGetter({ row });
            } else {
              newRow[col.headerName] = row[col.field];
            }
          });
          return newRow;
        });
    
        // 여기서부터는 이전 예시와 동일하게 xlsx 라이브러리를 사용하여 엑셀 파일 생성
        const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        XLSX.writeFile(
          workbook,
          "data(" + toDate?.format("YYYY-MM-DD") + ").xlsx"
        );
    };

    const get_gift_msg_list = async () => {
        const param: any = {
            command: "get_gift_msg_list",
            from_date: dayjs(fromDate).format("YYYYMMDD"),
            to_date: dayjs(toDate).add(1, "day").format("YYYYMMDD"),
        };

        setIsLoading(true);
        const res = await mainApi.get_gift_msg_list(param);
        if (res.code === "200") {
            setDataList(res.response.data_list);
            setRows(res.response.data_list);
        } else {
            console.error(res.response.error_msg);
            toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
                vertical: "top",
                horizontal: "center",
            });
        }
        setIsLoading(false);
    };

    return (
        <>
            <Box sx={{ p: 0.5, height: "100%" }}>
                <Stack direction={"column"} spacing={2}>
                    <Box component={"div"} sx={{ marginTop: "0.7rem" }}></Box>
                    <Box component={"div"}>
                        <Stack direction="row" spacing={1}>
                            <CardMedia
                                component="img"
                                image={"/images/icon-timeline.png"}
                                sx={{
                                width: "31px",
                                height: "38px",
                                objectFit: "contain",
                                filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15))",
                                }}
                            />
                            <Typography sx={{ display: "inline", fontWeight: "bold", fontSize: "1.5rem" }} component="span">
                                선물하기 카피 모음집
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Paper sx={{ p : 2, borderRadius: "1rem" }} elevation={5}>
                            <div 
                                style={{ display: "flex", justifyContent: "space-between" }}
                            >
                                <div 
                                    className="search-date-wrap"
                                    style={{ justifyContent: "start" }}
                                >
                                    <div className="search-date-wrap">
                                        <input
                                            type="date"
                                            value={
                                                fromDate && fromDate.isValid()
                                                ? fromDate.format("YYYY-MM-DD")
                                                : ""
                                            }
                                            onChange={(e) => setFromDate(dayjs(e.target.value))}
                                            required
                                        />
                                        <span>~</span>
                                        <input
                                            type="date"
                                            value={
                                                toDate && toDate.isValid()
                                                ? toDate.format("YYYY-MM-DD")
                                                : ""
                                            }
                                            onChange={(e) => setToDate(dayjs(e.target.value))}
                                            required
                                        />
                                    </div>
                                    <select
                                        id="question-option"
                                        value={selectedQuestionOption}
                                        onChange={handleSelectOptionChange}
                                    >
                                        <option value="target">대상/연령</option>
                                        <option value="keyword">상황/테마</option>
                                        <option value="content">내용</option>
                                        <option value="user_name">생성자</option>
                                    </select>
                                    <div className="srch-date-input-wrap">
                                        <input
                                            style={{ width: "240px" }}
                                            type="text"
                                            placeholder="검색어 입력..."
                                            value={question}
                                            onChange={(e) => {
                                                setQuestion(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <img
                                    src="/images/excel-icon.png"
                                    alt="EXCEL"
                                    style={{
                                    width: "40px",
                                    height: "40px",
                                    marginLeft: "5px",
                                    cursor: "pointer",
                                    }}
                                    onClick={() => downloadExcel(rows, columns)}
                                />
                            </div>
                            
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {page: 0, pageSize: 10},
                                    },
                                }}
                                getRowId={(row) => row.sk}
                                pageSizeOptions={[10, 15, 20]}
                                sx={{ mt: 2 }}
                            />
                        </Paper>
                    </Box>
                </Stack>
            </Box>
        </>
    )
}

export default GiftMsgList;