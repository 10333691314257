const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname.startsWith("dh02c1lkr9h0r");

const devConfig = {
  projectName: "lewis01-v1-Dev",
  systemNameEng: "LewisDev",
  systemName: "루이스Dev",
  systemNameExam: "루이스Dev가",
  mainApiUrl:
    "https://l68yrmdzx2.execute-api.ap-northeast-2.amazonaws.com/dev/adminMain",
  testApiUrl:
    "https://l68yrmdzx2.execute-api.ap-northeast-2.amazonaws.com/dev/adminMain",
  RecommendApiUrl:
    "https://q44g6b2tyvko7tinfpfhqcmxny0vogdh.lambda-url.ap-northeast-2.on.aws",
  NaverKeywordApiUrl:
    "https://fiiwbhjsghq47ffpluxkkjfuv40uvamp.lambda-url.ap-northeast-2.on.aws",
  sessionName: "lewis01-v1-adminss", // 사이트마다 달라야 함
  rememberidSSName: "lewis01-v1-rmbrid", // 사이트마다 달라야 함
  showTerms: true, // true : 사용자로그인, false : H-ART 로그인
  seed: "22333",
  sessionTimeout: 1000 * 60 * 60 * 3, // 180분
  qnaInfoSSName: "lewis01-v1-QnaInfo",
  defaultCompany: {
    name: "현대백화점",
    url: "http://localhost:3001/",
  },
  companiesProd: [
    {
      name: "현대백화점",
      url: "https://dh02c1lkr9h0r.cloudfront.net/",
    },
  ],
  companiesDev: [
    {
      name: "현대백화점",
      url: "http://localhost:3001",
    },
  ],
}

const prodConfig = {
  projectName: "lewis01-v1-Prod",
  systemNameEng: "Lewis",
  systemName: "루이스",
  systemNameExam: "루이스가",
  mainApiUrl:
    "https://eh732tqxp5.execute-api.ap-northeast-2.amazonaws.com/prod/adminMain",
  testApiUrl:
    "https://eh732tqxp5.execute-api.ap-northeast-2.amazonaws.com/prod/adminMain",
  RecommendApiUrl:
    "https://q44g6b2tyvko7tinfpfhqcmxny0vogdh.lambda-url.ap-northeast-2.on.aws",
  NaverKeywordApiUrl:
    "https://fiiwbhjsghq47ffpluxkkjfuv40uvamp.lambda-url.ap-northeast-2.on.aws",
  sessionName: "lewis01-v1-adminss", // 사이트마다 달라야 함
  rememberidSSName: "lewis01-v1-rmbrid", // 사이트마다 달라야 함
  showTerms: true, // true : 사용자로그인, false : H-ART 로그인
  seed: "22333",
  sessionTimeout: 1000 * 60 * 60 * 3, // 180분
  qnaInfoSSName: "lewis01-v1-QnaInfo",
  defaultCompany: {
    name: "현대백화점",
    url: "https://ehyundai.ai-lewis.com/",
  },
  companiesProd: [
    {
      name: "현대백화점",
      url: "https://ehyundai.ai-lewis.com/",
    },
    {
      name: "현대홈쇼핑",
      url: "https://hmall.ai-lewis.com",
    },
    {
      name: "현대디에프",
      url: "https://eddy.ai-lewis.com/",
    },
  ],
  companiesDev: [
    {
      name: "현대백화점",
      url: "http://localhost:3000",
    },
    {
      name: "현대홈쇼핑",
      url: "https://hmall.ai-lewis.com",
    },
    {
      name: "현대디에프",
      url: "https://eddy.ai-lewis.com/",
    },
  ],
};

export const appConfig = isLocalhost ? devConfig : prodConfig;
